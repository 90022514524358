import React from 'react'
import { Header, Footer } from '@components'
import '../../styles/main.scss'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main id="content" className="grid fade-in">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
