import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FormattedIcon } from '@icons'
import './archive.scss'

function Archive() {
  const data = useStaticQuery(graphql`
    query projectsQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/projects/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
              tech
              github
              inProgress
              external
            }
          }
        }
      }
    }
  `)
  const projects = data.allMarkdownRemark.edges
  return (
    <table>
      <thead>
        <tr>
          <th>Year</th>
          <th>Title</th>
          <th className="hide-mobile">Built with</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        {projects.length > 0 &&
          projects.map(({ node }, i) => {
            const { date, github, external, title, tech, inProgress } = node.frontmatter
            return (
              <tr key={i}>
                <td className="txt-neutral txt-x-small">{`${new Date(
                  date
                ).getFullYear()}`}</td>

                <td className="title txt-heavy txt-x-small">{title} {inProgress ? <span className="badge">Coming&nbsp;Soon</span> : null}</td>

                <td className="tech txt-neutral txt-x-small hide-mobile">
                  {tech.length > 0 &&
                    tech.map((item, i) => (
                      <span key={i}>
                        {item}
                        {''}
                        {i !== tech.length - 1 && (
                          <span className="bullet">&middot;</span>
                        )}
                      </span>
                    ))}
                </td>

                <td className="links">
                  <span>
                    {external && (
                      <a
                        href={external}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        aria-label="External Link"
                      >
                        <FormattedIcon name="External" />
                      </a>
                    )}
                    {github && (
                      <a
                        href={github}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        aria-label="GitHub Link"
                      >
                        <FormattedIcon name="GitHub" />
                      </a>
                    )}
                  </span>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default Archive
