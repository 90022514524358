import React from 'react'
import { IconExternal, IconGitHub } from './'

const FormattedIcon = ({ name }) => {
  switch (name) {
    case 'External':
      return <IconExternal />
    case 'GitHub':
      return <IconGitHub />
    default:
      return <IconExternal />
  }
}

export default FormattedIcon
