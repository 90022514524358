import React from 'react'
import { Link } from 'gatsby'
import { Logo } from '@icons'

const navItems = [
  { id: ``, text: `Latest` },
  { id: `work`, text: `Work` },
  // { id: `travel`, text: `Travel`},
  { id: `causes`, text: `Causes` },
]

const Header = () => (
  <header className="header">
    <a className="header__skip hide-screens button" href="#content">
      Skip to content
    </a>
    <nav className="header__nav" aria-label="main">
      <Link className="header__logo" to="/" aria-label="Brad Zickafoose">
        <Logo />
      </Link>
      <div className="header__links">
        {navItems.map(({ id, text }) => (
          <Link key={id} className="header__link" to={`/${id}`}>
            {text}
          </Link>
        ))}
      </div>
    </nav>
  </header>
)

export default Header
