import React from 'react'
import { today } from '@utils'
import social from '@data/social.json'

const Footer = () => (
  <footer className="footer" data-aos="fade">
    <p className="push-small--bottom">
      <img
        draggable="false"
        className="push-small--bottom"
        alt="✌️"
        src="https://s.w.org/images/core/emoji/11/svg/270c.svg"
        width="40"
        height="55"
      />
      <br />
      <strong>
        Enjoy the rest of your <span id="day">{today()}</span>!
      </strong>
    </p>
    <nav className="footer__nav grid__medium" aria-label="more resources">
      {social.map(({ name, url }) => (
        <a
          key={name}
          className="footer__nav-item"
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          {name}
        </a>
      ))}
    </nav>
  </footer>
)

export default Footer
