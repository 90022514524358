export function today() {
  let date = new Date()
  let weekday = new Array(7)

  weekday[0] = 'Sunday'
  weekday[1] = 'Monday'
  weekday[2] = 'Tuesday'
  weekday[3] = 'Wednesday'
  weekday[4] = 'Thursday'
  weekday[5] = 'Fri-yay'
  weekday[6] = 'Saturday'

  return weekday[date.getDay()]
}